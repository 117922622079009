import React from 'react';
import ReactImageVideoLightbox from 'react-image-video-lightbox';


const Lightbox = ({ media, startIndex, onClose }) => {
  return (
    <>
    <div className='lightbox-class'>
    <ReactImageVideoLightbox
      data={media}
      startIndex={startIndex}
      showResourceCount={true}
      onCloseCallback={onClose}
    />
    </div>
    </>
    
  );
};

export default Lightbox;