import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { AppRoute } from "../router/routerConstants";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { saveData } from "../utils/api/apiHelper";
import { SignUp } from "../utils/api/actionConstants";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as Validate from "../utils/validatorXerox";
const BenefitScreen = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confEmil, setConfEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const [secondConfirm, setSecondConfirm] = useState(false);
  const [thirdConfirm, setThirdConfirm] = useState(false);

  const { state } = useLocation();



  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onConEmailChange = (event) => {
    setConfEmail(event.target.value);
  }
  const showError = (message) => {
    toast.error(message);
    toast.clearWaitingQueue();
  };

  const handleSubmit = () => {
    const params = {
      name: name,
      email: email,
      password: password,
      userType: "Consumer",
    };
    let validate = Validate.dynamicValidationNew("RegisterConsumer", params);
    if (validate !== "") {
      showError(validate);
      return;
    }
    const regex = /^[^@]+$/;
    if(!regex.test(name)){
      showError("Username should not contain the '@' character");
      return;
    }
    if (email !== confEmil) {
      showError("EMAIL do not match");
      return;
    }

    if (!confirm) {
      showError("Please select Age confirmation");
      return;
    }
    if (!secondConfirm) {
      showError("Please select local, state, and federal laws");
      return;
    }
    if (!thirdConfirm) {
      showError("Please select terms and conditions");
      return;
    }

    dispatch(
      saveData({
        action: SignUp,
        definition: "RegisterConsumer",
        data: params,
      })
    ).then((r) => {
      if (r === 1) {
        NavigateTo(`${AppRoute.Search_Profile}/${state?.id}`);
        //NavigateTo(AppRoute.PAYMENT);
        // NavigateTo(AppRoute.REGIS_SUCCESS, true);
      }
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className="benfits-block">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Benefits</h1>
          </div>
          <div className="creator-benefits cusmr-bnfts-top-info">
            <ul>

              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Search for just the right girl and get custom content made just for you
              </li>

              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                More women signing up every day!
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Chat for free - no credit card required
              </li>
            </ul>
            <div className="form-block cusmr-bnft-form">
            <div className="new-user">
              Already have an Account?{" "}
              <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login here</span>
            </div>
            <form className="reg-horizontal-line">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Username - Not Real Name"
                  onChange={onNameChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={onEmailChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Confirm Email Address"
                  onChange={onConEmailChange}
                />
              </div>
              <div className="form-group">
                <div className="pass-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Pick a Strong Password"
                    onChange={onPasswordChange}
                  />
                  {!showPassword ? (
                    <span
                      className="hide-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <span
                      className="show-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    ></span>
                  )}
                </div>
              </div>
              <div className="agelaw">
                <span className="confirm">
                  <input
                    type="checkbox"
                    id="eighteenplus"
                    name="eighteenplus"
                    onChange={() => {
                      setConfirm(!confirm);
                    }}
                    checked={confirm}
                  />
                  <label for="eighteenplus">
                    Click to confirm you are at least 18 years of age
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="laws"
                    name="laws"
                    onChange={() => {
                      setSecondConfirm(!secondConfirm);
                    }}
                    checked={secondConfirm}
                  />
                  <label for="laws">
                    Click to confirm you are responsible for following all
                    local, state, and federal laws
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    onChange={() => {
                      setThirdConfirm(!thirdConfirm);
                    }}
                    checked={thirdConfirm}
                  />
                  <label for="terms">
                    Click to confirm that by using our site you agree to all{" "}
                    <a target="_blank" href="/terms_conditions">
                      Terms and Conditions
                    </a>
                  </label>
                </span>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleSubmit}
              />
             {/* <div className="new-user">
                Already a User?{" "}
                <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login</span>
              </div> */ }
            </form>
          </div>
           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BenefitScreen;
