import { Images } from "../../themes";
import { AppRoute } from "../../router/routerConstants";
import { useDispatch, useSelector } from "react-redux";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { saveData, showErrorInUI } from "../../utils/api/apiHelper";
import { LoginUser } from "../../utils/api/actionConstants";
import { useState } from "react";
import { getStorage, getStorageJson } from "../../utils/localStorage";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { IsUserSubscribed } from "../../utils/Common";
import validator from "validator";

const LoginScreen = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const [secondConfirm, setSecondConfirm] = useState(false);
  const [thirdConfirm, setThirdConfirm] = useState(false);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (email == "") {
      showErrorInUI("Please enter your email");
      return;
    }

    if (!validator.isEmail(email)) {
      showErrorInUI("Please enter valid email");
      return;
    }

    if (password == "") {
      showErrorInUI("Please enter password");
      return;
    }

    if (!confirm) {
      showErrorInUI("Please select Age confirmation");
      return;
    }
    if (!secondConfirm) {
      showErrorInUI("Please select local, state, and federal laws");
      return;
    }
    if (!thirdConfirm) {
      showErrorInUI("Please select terms and conditions");
      return;
    }

    dispatch(
      saveData({
        action: LoginUser,
        definition: "Login",
        data: {
          email: email,
          password: password,
        },
      })
    ).then((r) => {
      if (r === 1) {
        if (isCreator()) {
          if (isSetupComplete()) {
            NavigateTo(AppRoute.CreatorHome, true);
          } else {
            NavigateTo(AppRoute.SETUP, true);
          }
        } else {
          NavigateTo(AppRoute.CONS_Search, true);
        }
      }
    });
  };

  const isCreator = () => {
    const user = getStorageJson("user");
    return user.role === "Creator";
  };

  const isSetupComplete = () => {
    const user = getStorageJson("user");
    return user.isSetupComplete;
  };

  const IsSubscribed = () => {
    const user = getStorageJson("user");
    return IsUserSubscribed(user);
  };

  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container small-container">
        <div className="login-wrap">
          <div className="logo-block">
            <span>
              <img src={Images.logo_login} alt="logo" />
            </span>
          </div>
          <div className="form-block">
          <div className="my-ac-form-info log-nt">
                <div className="form-inner-holder">
                  <p><b>The site will be in maintenance mode from 6:30 a.m. to 8:30 a.m. every day.</b></p>
                </div>
            </div>
            <form>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={onEmailChange}
                />
              </div>
              <div className="form-group">
                <div className="pass-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={onPasswordChange}
                  />
                  {!showPassword ? (
                    <span
                      className="hide-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <span
                      className="show-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    ></span>
                  )}
                </div>
              </div>
              <div className="agelaw">
                <span className="confirm">
                  <input
                    type="checkbox"
                    id="eighteenplus"
                    name="eighteenplus"
                    onChange={() => {
                      setConfirm(!confirm);
                    }}
                    checked={confirm}
                  />
                  <label for="eighteenplus">
                    Click to confirm you are at least 18 years of age
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="laws"
                    name="laws"
                    onChange={() => {
                      setSecondConfirm(!secondConfirm);
                    }}
                    checked={secondConfirm}
                  />
                  <label for="laws">
                    Click to confirm you are responsible for following all
                    local, state, and federal laws
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    onChange={() => {
                      setThirdConfirm(!thirdConfirm);
                    }}
                    checked={thirdConfirm}
                  />
                  <label for="terms">
                    Click to confirm that by using our site you agree to all{" "}
                    <a target="_blank" href="/terms_conditions">
                      Terms and Conditions
                    </a>
                  </label>
                </span>
              </div>
              <span
                className="forgot-pass"
                onClick={() => NavigateTo(AppRoute.FORGOTPASS)}
              >
                Forgot Password?
              </span>
              <input
                className="submit"
                type="button"
                defaultValue="Login"
                onClick={handleSubmit}
              />
              <div className="new-user">
                New User?
                <span onClick={() => NavigateTo(AppRoute.REGISTER)}>
                  Register
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginScreen;
