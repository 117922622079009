import * as common from "../Common";
const axios = require("axios");
//export const Baseurl = "http://localhost:3030/";
// export const Baseurl = "http://54.234.129.155:3100/";
export const Baseurl = "https://api.arouzed.com/";
// export const Baseurl = "http://localhost:3001/";
//export const Baseurl = "https://dev-api.arouzed.com/";

function getHeader() {
  let token = common.readToken();
  if (token) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    };
  } else {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    }; 
  }
}

function getHeaderForm() {
  let token = common.readToken();
  if (token) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    };
  } else {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
}

export const GET = async function (url) {
  url = Baseurl + url;

  return axios
    .get(url, getHeader())
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((ex) => {
      return ex;
    });
};

export const POST = async function (url, data = {}) {
  url = Baseurl + url;

  return axios
    .post(url, data, getHeader())
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((ex) => {
      return ex;
    });
};

export const UPLOAD = async function (url, data) {
  url = Baseurl + url;

  return axios
    .post(url, data, getHeaderForm())
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((ex) => {
      return ex;
    });
};

export const PUT = async function (url, data) {
  url = Baseurl + url;
  return axios
    .put(url, data, getHeader())
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((ex) => {
      return ex;
    });
};

export const DELETE = async function (url) {
  url = Baseurl + url;
  return axios
    .delete(url, getHeader())
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((ex) => {
      return ex;
    });
};

export const OpenGET = (url) => {
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((ex) => {
      return ex;
    });
};
