import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { AppRoute } from "../router/routerConstants";
import Header from "../components/Header";
import Footer from "../components/Footer";
const BenefitScreen = () => {
  const [NavigateTo] = useCustomNavigate();
  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className="benfits-block">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Benefits</h1>
          </div>
          <div className="creator-benefits">
            <h3>Creator Benefits</h3>
            <ul>
              {/* <li>Private - Discreet - Sexting</li> */}
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Keep 100% of Your Earnings
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Cultivate One-on-One Relationships with Members
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Create Custom Content Tailored to Individual Desires
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Blur Your Profile Pictures
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Block Your Profile From Selected States
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Earn Income Privately & Discreetly
              </li>
            </ul>
            <p>These points highlight the personalized, quality-focused approach of <a href=".">Arouzed.com</a>, distinguishing us from other platforms.</p>
            <span
              className="submit"
              onClick={() => NavigateTo(AppRoute.REGISTER)}
            >
              Continue
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BenefitScreen;
