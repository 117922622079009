import React, { useState } from "react";
import { useEffect } from "react";
import { Images } from "../themes";
import { showError } from "../utils/Common";
import * as CommonFunc from "../utils/Common";

import "../assets/css/reactTags.css";
import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function TagsInput({ onChange, selectedTags, tagsList, placeholder = "Tags" }) {
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const cTags = selectedTags.map((r) => {
      return { text: r, id: r };
    });

    setTags(cTags);
  }, [selectedTags]);

  useEffect(() => {
    const cSugges = tagsList.map((r) => {
      return { text: r, id: r };
    });

    setSuggestions(cSugges);
  }, [tagsList]);

  const handleDeleteTags = (i) => {
    if (tags.length > 0) {
      updateParent(tags.filter((tag, index) => index !== i));
    }
  };

  const handleAdditionTags = (tag) => {
    if (tags.length === 5) {
      CommonFunc.showError(
        `You cannot add more than 5 ${
          placeholder === "Tags" ? "tags" : "zip-codes"
        }.`
      );
      return;
    }

    if (suggestions.length > 0) {
      const filteredTags = suggestions.filter((r) => r.id === tag.id);

      if (filteredTags.length > 0) {
        updateParent([...tags, tag]);
      } else {
        CommonFunc.showError(
          "This tag is not in the list. Please select from the list."
        );
      }
    } else {
      updateParent([...tags, tag]);
    }
  };

  const updateParent = (options) => {
    setTags(options);
    if (onChange && typeof onChange === "function")
      onChange(options.map((r) => r.id));
  };

  return (
    <div className="App">
      <div>
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          placeholder={placeholder}
          delimiters={delimiters}
          handleDelete={handleDeleteTags}
          handleAddition={handleAdditionTags}
          allowDragDrop={false}
          inputFieldPosition="top"
        />
      </div>
    </div>
  );
}

export default TagsInput;
