import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import backBtn from '../../../assets/images/back-btn.png';
import { useRef, useState, useEffect } from "react";
import { UploadFile, UpdateUserpics,UpdateCreator } from "../../../utils/api/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import { UpdateIsBlur } from "../../../utils/api/actionConstants";
import * as CommonFunc from "../../../utils/Common";
import { updateUserData } from "../../../redux/userReducer";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { AppRoute } from "../../../router/routerConstants";

/*const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB max file size
const ALLOWED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png"];*/

const ProfileImages = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const [NavigateTo] = useCustomNavigate();
    const [imgUrl, setImgUrl] = useState("");
    const [img, setImg] = useState();
    const [loading, setLoading] = useState(false);
    const [img1, setImg1] = useState();
    const [img2, setImg2] = useState();
    const [img3, setImg3] = useState();
    const [img4, setImg4] = useState();
    
    const [imgurlArray, setImgUrlArray] = useState([{"isBlurImages":false,"source":''}, {"isBlurImages":false,"source":''}, {"isBlurImages":false,"source":''}, {"isBlurImages":false,"source":''}]);
    let userPics = useSelector((state) => state.user.userData?.allProfilePics);
    const userData = useSelector((state) => state.user.userData);

    useEffect(() => {
        //Remove after image approval from admin
        if (userPics && userPics.length > 4) {
          userPics = userPics.slice(-4).map((r) => {
            return {
              "isBlurImages":r.isBlurImages,"source":r.source
            }
          });
        }
        if (userPics && userPics.length === 4) {
          if (typeof userPics[0] === "object") {
            userPics = userPics.map((r) => {
              return {
                "isBlurImages":r.isBlurImages,"source":r.source
              }
            });
          }
          setImgUrlArray(userPics);
          setImg1(userPics[0].source);
          setImg2(userPics[1].source);
          setImg3(userPics[2].source);
          setImg4(userPics[3].source);
        }
        setImgUrl(userData.profilePic);
      }, []);

      const onImageChange1 = (e, imgNum) => {
        const [file] = e.target.files;

        /*if (file) {
          if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            CommonFunc.showErrorFileType("Invalid file type. Please upload a JPEG or PNG image.");
            setLoading(false);
            return;
          }
    
          if (file.size > MAX_FILE_SIZE) {
            CommonFunc.showErrorFileSize("File is too large. Please upload an image under 2MB.");
            setLoading(false);
            return;
          }
        }*/
    
        if (imgNum === 1) {
          setImg1(URL.createObjectURL(file));
        } else if (imgNum === 2) {
          setImg2(URL.createObjectURL(file));
        } else if (imgNum === 3) {
          setImg3(URL.createObjectURL(file));
        } else if (imgNum === 4) {
          setImg4(URL.createObjectURL(file));
        }
        setLoading(true);
        imageUpload(file, imgNum);
      };

      const imageUpload = (file, imgNum) => {
        const fd = new FormData();
        fd.append("file", file);
        let imgUrls = imgurlArray;
        dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
          if (r.length > 0) {
            const imgurlArray1 = imgurlArray.map((obj,key) =>
              key=== (imgNum-1) ? { ...obj, source: r[0] } : obj
            );
            setLoading(false);
            setImgUrlArray(imgurlArray1);
          }
        });
      };

      const DefaultView = ({ imgNum }) => {
        return (
          <div className="inner-uploaded">
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={(e) => onImageChange1(e, imgNum)}
            />
            <img className="upload-icon" src={Images.uploadImgIcon} />
          </div>
        );
      };

      const ImageView = ({ imgNum }) => {
        let image = "";
        if (imgNum === 1) {
          image = img1;
        } else if (imgNum === 2) {
          image = img2;
        } else if (imgNum === 3) {
          image = img3;
        } else if (imgNum === 4) {
          image = img4;
        }
    
        return (
          <div className="inner-uploaded">
            <figure>
              <img src={image} />
              <span className="upload-link">
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => onImageChange1(e, imgNum)}
                />
                <img src={Images.cameraIcon2} alt="" />
              </span>
            </figure>
          </div>
        );
      };

      const handleContinue = (e) => {
        e.preventDefault();
    
        const imgNotSelected = imgurlArray.filter((r) => r.source === "");
        if (imgNotSelected.length > 0) {
          CommonFunc.showError("Please select all Images");
          return;
        }
        if (!imgUrl || imgUrl == "") {
          CommonFunc.showError("Please select a profile Image");
          return;
        }

        const params1 = {
          profilePic: imgUrl,
          isProfileImagesCompleted: true
        };
        dispatch(updateUserData(params1));
        const payload1 = {
          action: UpdateCreator,
          data: params1,
        };
        dispatch(saveData(payload1)).then((r) => {});
    
        const params = {
          allProfilePics: imgurlArray,
          isProfileImagesCompleted: true
        };
        dispatch(updateUserData(params));
    
        const payload = {
          action: UpdateUserpics,
          data: { profiles: imgurlArray },
        };
        dispatch(saveData(payload)).then((r) => {
          if (r === 1) {
             CommonFunc.showSuccess("Data saved successfully");
             NavigateTo(AppRoute.Profile);
          }
        });
      };

    const onImgClick = (event) => {
        ref.current.click();
      };
      const onImageChange = (e) => {
        setLoading(true);
        const [file] = e.target.files;
        /*if (file) {
          if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            CommonFunc.showErrorFileType("Invalid file type. Please upload a JPEG or PNG image.");
            setLoading(false);
            return;
          }
          if (file.size > MAX_FILE_SIZE) {
            CommonFunc.showErrorFileSize("File is too large. Please upload an image under 2MB.");
            setLoading(false);
            return;
          }
        }*/
        const fd = new FormData();
        fd.append("file", file);
        setImg(URL.createObjectURL(file));
        setImgUrl("");
        dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
          setLoading(false);
          if (r.length > 0) {
            setImgUrl(r[0]);
          }
        });
      };

      const onBlurChange = (value,id) => {
        const imgurlArray1 = imgurlArray.map((obj,key) =>
          key=== id ? { ...obj, isBlurImages: value } : obj
        );
       
        setImgUrlArray((imgurlArray1));
      };
      
      const onBlurUserChange = (value) => {
        dispatch(
          saveData({
            action: UpdateIsBlur,
            data: {},
          })
        );
        const params1 = {
          isBlur: value,
        };
        dispatch(updateUserData(params1));
      };
    return (
        <>
            <div className="wrapper">
            <Header showLogout={true} />
            <FullScreenLoader loading={loading} />
                <div className="container">
                    <div className="request-purchased-wrap">
                        <div className="heading-block">
                            <span className="back-btn" onClick={() => NavigateTo(-1)}><img src={backBtn} alt="back-btn"/></span>
                        </div>
                        <div className="page-heading-block text-center">
                            <h2>Profile Images</h2>
                        </div>
                        <div className="profile-img-seprte">
                        <div className="my-ac-form-info pad-rmv">
                          <div className="form-inner-holder">
                              <p><b>If you experience any issues uploading photos, please try smaller photos. Issues with photo uploading are generally caused by images being too large.</b></p>
                              <p><b>If you still experience issues, email support@arouzed.com - Thank You</b></p>
                          </div>
                          </div>
                          <div className="form-inner-holder">
                            <div className="form-inner-holder-info">
                              <p>Please upload one Profile Image Selfie. Please make sure there is no adult content or nudity of any kind in this Profile Image. To blur this image, simply select the Blur option next to each image.</p>
                            </div>
                              <div className="upload-img">
                                <figure>
                                    <img
                                    src={
                                        imgUrl != "" ? imgUrl : img ?? Images.default_profile
                                    }
                                    alt="profile"
                                    />
                                </figure>
                                <span className="upload-icon" onClick={onImgClick}>
                                    <img src={Images.cameraIcon} alt="" />
                                    <input
                                    ref={ref}
                                    type="file"
                                    id="input_file"
                                    accept=".jpg,.jpeg,.png"
                                    onChange={onImageChange}
                                    style={{ display: "none" }}
                                    />
                                </span>
                                </div>
                                <div className="main-profile-content">Main Profile Image</div> 
                                <div className="blur-image">
                                <label for="blutcheck">Blur profile image</label>
                                <input
                                    id="blutcheck"
                                    type="checkbox"
                                    onChange={(e) => onBlurUserChange(e.target.checked)}
                                    checked={userData.isBlur}
                                ></input>
                                </div>
                          </div>
                        </div>
                        
                        <div className="form-block">
                          <div className="form-inner-holder">
                          <div className="form-inner-holder-info">
                              <p>Please upload four fun, flirty, sexy Profile Images. Please make sure there is no adult content or nudity of any kind in these Profile Images. Flirty, fun, sexy images are encouraged but please save any nudity or adult content for the Private Introductory Folder in your Creator Dashboard.</p>
                            </div>
                              <form>
                            
                            <div className="uploded-imges">
                                <div className="row">
                                <div className="col-6 each-blur-optn">
                                <div className="blur-image">
                                    <label for="fblutcheck">Blur General Profile Pic</label>
                                    <input
                                    id="fblutcheck"
                                    type="checkbox"
                                    onChange={(e) => onBlurChange(e.target.checked,0)}
                                    checked={imgurlArray[0].isBlurImages}
                                    ></input>
                                </div>
                                    {img1 ? (
                                    <ImageView imgNum={1} />
                                    ) : (
                                    <DefaultView imgNum={1} />
                                    )}
                                </div>
                                <div className="col-6 each-blur-optn">
                                <div className="blur-image">
                                    <label for="fblutcheck">Blur General Profile Pic</label>
                                    <input
                                    id="fblutcheck"
                                    type="checkbox"
                                    onChange={(e) => onBlurChange(e.target.checked,1)}
                                    checked={imgurlArray[1].isBlurImages}
                                    ></input>
                                </div>
                                    {img2 ? (
                                    <ImageView imgNum={2} />
                                    ) : (
                                    <DefaultView imgNum={2} />
                                    )}
                                </div>
                                <div className="col-6 each-blur-optn">
                                <div className="blur-image">
                                    <label for="fblutcheck">Blur General Profile Pic</label>
                                    <input
                                    id="fblutcheck"
                                    type="checkbox"
                                    onChange={(e) => onBlurChange(e.target.checked,2)}
                                    checked={imgurlArray[2].isBlurImages}
                                    ></input>
                                </div>
                                    {img3 ? (
                                    <ImageView imgNum={3} />
                                    ) : (
                                    <DefaultView imgNum={3} />
                                    )}
                                </div>
                                <div className="col-6 each-blur-optn">
                                <div className="blur-image">
                                    <label for="fblutcheck">Blur General Profile Pic</label>
                                    
                                    <input
                                    id="fblutcheck"
                                    type="checkbox"
                                    onChange={(e) => onBlurChange(e.target.checked,3)}
                                    checked={imgurlArray[3].isBlurImages}
                                    ></input>
                                </div>
                                    {img4 ? (
                                    <ImageView imgNum={4} />
                                    ) : (
                                    <DefaultView imgNum={4} />
                                    )}
                                </div>
                                </div>
                            </div>
                            <input
                                className="submit"
                                type="button"
                                defaultValue="Save"
                                onClick={handleContinue}
                            />
                            </form>

                          </div>
                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default ProfileImages;