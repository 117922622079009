import { generateRandomString, viewDateDDMMYYY } from "../utils/Common";

const ShowReportRow = ({ rows }) => {
  return (
    rows &&
    rows.length > 0 &&
    rows.map((row) => {
      return (
        <div className="view-request-list" key={generateRandomString()}>
          <div className="view-request-img">
            <figure>
              <img src={row?.profilePic} />
            </figure>
          </div>
          <div className="view-request-detail">
            <div className="view-request-inner">
              <h3>{row?.name}</h3>
              <p>{viewDateDDMMYYY(row?.createdAt)}</p>
            </div>
            <div className="price">${row?.amount}</div>
          </div>
        </div>
      );
    })
  );
};

export default ShowReportRow;
