import { useEffect, useState } from "react";
import { saveData } from "../../../utils/api/apiHelper";
import { useSelector, useDispatch } from "react-redux";
import { updateUserData } from "../../../redux/userReducer";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { UpdateCreator } from "../../../utils/api/actionConstants";
import FullScreenLoader from "../../../components/FullScreenLoader";
import States from "../../../utils/US_states.json";
import * as CommonFunc from "../../../utils/Common";
import backBtn from '../../../assets/images/back-btn.png'
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { AppRoute } from "../../../router/routerConstants";

const StateExclusionList = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state.user.userData);
    const [NavigateTo] = useCustomNavigate();

    useEffect(() => {
        const userState = Array.isArray(userData?.state) ? userData?.state : [];
        setState(userState);
    }, []);

    const handleContinue = (e) => {
        e.preventDefault();
        const params = {
          state: state
        };
        dispatch(updateUserData(params));
        const payload = {
          action: UpdateCreator,
          data: params,
        };
        dispatch(saveData(payload)).then((r) => {
          if (r === 1) {
            CommonFunc.showSuccess("Data saved successfully");
            NavigateTo(AppRoute.Profile);
          }
        });
      };

      const handleChange = (e) => {
        if (state.length === 49) {
          CommonFunc.showError("Only 49 states are allowed.");
          return;
        }
        if (state.includes(e.target.name)) {
          const newStates = state.filter((r) => r != e.target.name);
          setState(newStates);
        } else {
          setState([...state, e.target.name]);
        }
      };

      const showStates = () => {
        return (
          <div className="profile-states">
            {States.map((st) => {
              return (
                <div className="setting-item">
                  <input
                    type="checkbox"
                    id={st.label}
                    key={st.label}
                    name={st.label}
                    onChange={(e) => handleChange(e)}
                    checked={state.includes(st.label)}
                  />
                  <label className="setting-item-name" for={st.label}>
                    {st.label}
                  </label>
                </div>
              );
            })}
          </div>
        );
      };

    return (
        <>
        <div className="wrapper">
            <Header showLogout={true} />
            <FullScreenLoader loading={loading} />
            <div className="container">
                <div className="request-purchased-wrap">
                    <div className="heading-block">
                        <span className="back-btn" onClick={() => NavigateTo(-1)}><img src={backBtn} alt="back-btn"/></span>
                    </div>
                    <div className="page-heading-block stex text-center">
                        <h2>Profile Display Exclusion</h2>
                        <p>You may select states where your profile will not be displayed..</p>
                    </div>
                    <form>  
                        <div className="all-input">
                            <div className="form-group">
                                {showStates()}
                            </div>
                        </div>
                        <input
                            className="submit"
                            type="button"
                            defaultValue="Save"
                            onClick={handleContinue}
                        />
                    </form>
                </div>
            </div>
            <Footer/>
            </div>
        </>
    );
}

export default StateExclusionList;