import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useChatMessages, useHasMore, useUser } from "../../hooks/useStore";
import { toast } from "react-toastify";
import { AppRoute } from "../../router/routerConstants";
import {
  UnReadAllChatMessage,
  LoadChatMessage,
  ReadAllChatMessage,
  AddMessage,
  UpdateCurrentChatRead,
  MakePaymentRequest,
  SaveMedia,
  Cons_LoadFolder,
  ReportUser,
} from "../../utils/api/actionConstants";
import { getData, saveData } from "../../utils/api/apiHelper";
import { parse, showError, stringify } from "../../utils/Common";
import { ClearChatMessage } from "../../redux/chatReducer";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Images from "../../themes/Images";
import TextMessage from "../../components/chat/TextMessage";
import ImageMessage from "../../components/chat/ImageMessage";
import PriceMessage from "../../components/chat/PriceMessage";
import VideoMessage from "../../components/chat/VideoMessage";
import ChooseFolder from "../../components/ChooseFolder";
import FullScrenLoader from "../../components/FullScreenLoader";
import socket from "../../socket/socket";
import { getDateFull, getDate } from "../../utils/Common";
import ReportUserComponent from "../../components/chat/Report";
import ReportMessage from "../../components/chat/ReportMessage";
import { Link } from "react-router-dom"

const ChatMessage = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const currentUser = useUser();

  const folder = useSelector((state) => state.folder);

  const [value, setValue] = useState("");
  const [chat, setChat] = useState({});
  const [openRequest, setOpenRequest] = useState(false);
  const [isReportUser, setIsReportUser] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [isOthers, setIsOthers] = useState(false);
  const [reportMessage, setReportMessage] = useState("");
  const [requestPrice, setRequestPrice] = useState(0);
  const [chooseFolder, setChooseFolder] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState();
  const [loading, setLoading] = useState(false);

  const chatMessages = useChatMessages();
  const hasMore = useHasMore();
  let messagesEndRef = useRef(null);

  let chatBody = state.chat;
  useEffect(() => {
    if (!state || !state.chat || !state.chat.id) {
      NavigateTo("/");
    }
    setChat(chatBody);
  }, []);

  const getReceiver = (v) => {
    if (v.firstUser && v.firstUser.id === currentUser.id) {
      return v.secondUser;
    }
    if (v.secondUser && v.secondUser.id === currentUser.id) {
      return v.firstUser;
    }
  };
  const receiver = getReceiver(chatBody);

  useEffect(() => {
    if (!isCreator()) {
      dispatch(
        getData({ action: Cons_LoadFolder, data: { limit: 70, offset: 0 } })
      );
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getData({ action: LoadChatMessage, data: { id: chatBody.id } }));
    readAllChatMessage();
  }, []);

  /**
   *
   * Update All chat message to read as we are in same chat window.
   *
   */
  const readAllChatMessage = () => {
    dispatch(
      saveData({ action: ReadAllChatMessage, data: { id: chatBody.id } })
    );
    dispatch({ type: UpdateCurrentChatRead, payload: {} });
    dispatch(getData({action: UnReadAllChatMessage, }));
  };
  const fetchOlderChat = () => {
    if (chatMessages.length > 0) {
      dispatch(
        getData({
          action: LoadChatMessage,
          data: { id: chat.id, lastMessageAt: chatMessages[0].createdAt },
        })
      );
    }
  };

  useEffect(() => {
    if (folder.toSendFiles) {
      folder.selectedFiles.map((r) => {
        messageMediaHandler(r.source, r.type === "image" ? "Image" : "Video");
      });

      dispatch({
        type: "UpdateSelectedFiles",
        payload: { files: [], status: false },
      });
    }
  }, [folder.toSendFiles]);

  useEffect(() => {
    socket.emit("sign-in", currentUser);
    setupSocketListeners();
  }, []);

  useEffect(() => {
    const root = document.getElementById("scrollableDiv");
    if (hasMore && root && root.scrollHeight <= root.clientHeight) {
      fetchOlderChat();
    }
  }, [hasMore]);

  /**
   * this hook is for cleaning up the redux store when we move out of this chat.
   */
  useEffect(() => () => dispatch(ClearChatMessage()), []);
  useEffect(() => () => socket.off("message", onMessageRecieved), []);

  const onClientDisconnected = () => {
    console.log(
      "Connection Lost from server please check your connection.",
      "Error!"
    );
  };

  const isCreator = () => {
    return currentUser.role === "Creator";
  };

  /**
   * Established new connection if reconnected.
   */
  const onReconnection = () => {
    if (currentUser) {
      socket.emit("sign-in", currentUser);
      console.log("Connection Established.", "Reconnected!");
    }
  };

  const onMessageRecieved = (message) => {
    if (message.chatId && chatBody.id && chatBody.id !== message.chatId) {
      return;
    }
    dispatch({ type: AddMessage, payload: message });
    readAllChatMessage();
  };

  /**
   * Setup all listeners
   */

  const setupSocketListeners = () => {
    socket.on("message", onMessageRecieved);
    socket.on("reconnect", onReconnection);
    socket.on("disconnect", onClientDisconnected);

    socket.on("online", (obj) => {
      if (obj.userId === receiver.id) {
        let updatedChat = { ...chat };
        updatedChat.online = true;
        setChat(updatedChat);
      }
    });
    socket.on("offline", (obj) => {
      if (obj.userId === receiver.id) {
        let updatedChat = { ...chat };
        updatedChat.online = false;
        setChat(updatedChat);
      }
    });
  };

  const onSendMessage = (e) => {
    e.preventDefault();

    if (value.length > 0) {
      dispatch(
        saveData(
          {
            action: MakePaymentRequest,
            data: {
              chatId: chatBody.id,
              message: value,
              userId: currentUser.id,
              chatType: { name: "Message" },
            },
          },
          true
        )
      ).then((resp) => {
        setValue("");
        if (resp) {
          messageHandler(resp, "Message");
        }
      });
    }
  };

  const createPaymentRequest = (e) => {
    e.preventDefault();

    if (requestPrice > 0) {
      setOpenRequest(false);
      dispatch(
        saveData(
          {
            action: MakePaymentRequest,
            data: {
              chatId: chatBody.id,
              to: receiver.id,
              message: stringify({
                amount: requestPrice,
                isPaid: false,
                currency: "$",
                rejected: false,
              }),
              userId: currentUser.id,
              chatType: { name: "Payment Request" },
            },
          },
          true
        )
      ).then((resp) => {
        setRequestPrice();
        if (resp) {
          messageHandler(resp, "Payment Request");
        }
      });
    }
  };

  const onPay = (entry) => {
    let body = parse(entry.message);
    dispatch(
      saveData(
        {
          action: MakePaymentRequest,
          data: {
            id: entry.id,
            relatedId: entry.relatedId,
            amount: body.amount,
            chatId: chatBody.id,
            to: receiver.id,
            message: stringify({
              amount: body.amount,
              currency: "$",
            }),
            userId: currentUser.id,
            chatType: { name: "Processing Payment" },
          },
        },
        true
      )
    ).then((resp) => {
      
      if (resp.status ===301) {
        NavigateTo(AppRoute.Cons_AddMoney, false, { url: "chat" });
      }else{
        messageHandler(resp, "Processing Payment");
      }
    });
  };

  const reportUser = (message) => {
    setIsReportUser(true);
  };

  const toggleReportUser = () => {
    setIsReportUser(false);
    setIsOthers(false);
  };

  const onContinueReport = () => {
    if (reportReason === "") {
      showError("Please select an option");
      return;
    }
    setIsReportUser(false);
    setIsOthers(true);
  };

  const onReportSubmit = () => {
    const data = {
      reason: reportMessage,
      reportType: reportReason,
      type: currentUser.roleType,
      userId: receiver.id,
    };

    dispatch(saveData({ action: ReportUser, data: data }));

    setIsReportUser(false);
    setIsOthers(false);
  };

  const messageHandler = (message, type = "Messsage") => {
    message.chatType = {
      name: type,
    };
    message.to = receiver.id;
    message.hash = Math.floor(Math.random() * 9000000) + 1000000;
    socket.emit("message", message);
    dispatch({ type: AddMessage, payload: message });
  };

  const messageMediaHandler = (message, type = "Image") => {
    dispatch(
      saveData(
        {
          action: MakePaymentRequest,
          data: {
            chatId: chatBody.id,
            message: message,
            userId: currentUser.id,
            chatType: { name: type },
            to: receiver.id,
            hash: Math.floor(Math.random() * 9000000) + 1000000,
          },
        },
        true
      )
    ).then((resp) => {
      setValue("");
      if (resp) {
        messageHandler(resp, type);
      }
    });

    // socket.emit("message", media_msg);
    // const tempMessage = Object.assign({}, media_msg);
    // tempMessage.chatType = {
    //   name: ,
    // };

    // setTimeout(() => {
    //   dispatch({ type: AddMessage, payload: tempMessage });
    // }, 1000);
  };

  const ChatRenderer = () => {
    let msg = [...chatMessages];
    if (msg.length === 0) {
      return;
    }
    let currentDate = new Date(getDate(msg[msg.length - 1].createdAt));
    let showDate = msg[msg.length - 1].createdAt;
    return msg.reverse().map((row, k) => {
      if (currentDate > new Date(getDate(row.createdAt))) {
        let ss = showDate;
        currentDate = new Date(getDate(row.createdAt));
        showDate = row.createdAt;
        return (
          <>
            <div>{getDateFull(ss)}</div>
            <div key={k}>{SelectMessageTemplate(row)}</div>
          </>
        );
      }

      return <div key={k}>{SelectMessageTemplate(row)}</div>;
    });
  };

  const SelectMessageTemplate = (row) => {
    switch (row.chatType.name) {
      case "Message":
        return TextMessage(row, row.userId === currentUser.id);
      case "Image":
        return ImageMessage(
          row,
          row.userId === currentUser.id,
          showChooseFolder
        );
      case "Video":
        return VideoMessage(
          row,
          row.userId === currentUser.id,
          showChooseFolder
        );
      case "Payment Request":
        return (
          <div>
            {currentUser.roleType === "Consumer" && (
              <div className="arouzed-message">
                <p>
                  If the content provided is not quite what you had in mind, let
                  your friend know. Otherwise, please go ahead and click the
                  link below to send payment.
                </p>
                <span>-Arouzed</span>
              </div>
            )}
            {PriceMessage(row, row.userId === currentUser.id, {
              onPay,
              reportUser,
              type: "Payment Request",
            })}
          </div>
        );

      case "Processing Payment":
        return PriceMessage(row, row.userId === currentUser.id, {
          type: "Processing Payment",
        });
      default:
        return TextMessage(row, row.userId === currentUser.id);
    }
  };

  const attachFile = () => {
    NavigateTo(AppRoute.FOLDER, false, { from: "chat" });
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const showChooseFolder = (chat) => {
    setSelectedMedia(chat);
    setChooseFolder(true);
  };
  const hideChooseFolder = () => {
    setChooseFolder(false);
  };

  const saveMedia = (id) => {
    hideChooseFolder();
    dispatch(
      saveData(
        {
          action: SaveMedia,
          data: {
            folderId: id,
            source: selectedMedia.message,
            ownerId: selectedMedia.userId,
          },
        },
        true
      )
    ).then((resp) => {
      if (resp) {
        toast.success("Media Saved");
      }
    });
  };

  if (chatMessages && loading) {
    setLoading(false);
  }

  return (
    <div className="wrapper">
      <FullScrenLoader loading={loading} />
      <Header socket={socket} />
      <div className="container">
        <div className="chat-box-wrap">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>{receiver.name}</h1>
            <div className="chat-img">
              <figure>
                <img
                  src={
                    receiver?.profilePic && receiver?.profilePic != ""
                      ? receiver?.profilePic
                      : Images.default_profile
                  }
                  alt=""
                />
              </figure>
              <span className={chat.online ? "online" : "offline"}></span>
            </div>

            <span
              href="#"
              className="profile-option-dot"
              onClick={(e) => reportUser(receiver)}
            >
              ︙
            </span>
          </div>
          <div className="my-ac-form-info">
          <div className="form-inner-holder consumer-info-text">
          <p><b>Welcome to <a href=".">Arouzed.com</a> Chat!</b></p>
          <p>Please read through our expectations for both Creators and Consumers before chatting:</p>
          <ul>
            <li><b>Creators:</b> Communicate with consumers to 100% understand their preferences to deliver quality content.</li>
            <li><b>Consumers:</b> Be very clear about your desires & pay promptly. Failure to pay for received content will result in account suspension.</li>
          </ul>
          
          <p><b>Respect each other, collaborate, and have fun!</b><br></br><b><a href=".">Arouzed.com</a> thrives on a positive community. Let's keep it that way!</b></p>
          <Link className="btn btn-primary" to="/chat/information">Tell Me More</Link>
          </div>
          </div>
          
          <div
            className="chat-box-inner"
            id="scrollableDiv"
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScroll
              dataLength={chatMessages.length} //This is important field to render the next data
              next={() => fetchOlderChat()}
              hasMore={hasMore}
              style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
              inverse={true} //
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
              // endMessage={
              //   <p style={{ textAlign: "center" }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            >
              {ChatRenderer()}
            </InfiniteScroll>
            <div
              style={{ float: "left", clear: "both" }}
              ref={messagesEndRef}
            ></div>
          </div>
          <div className="chat-type-box">
            {isCreator() && (
              <span className="add-link" onClick={(e) => setOpenRequest(true)}>
                <img src={Images.dollarIcon2} alt="" />
              </span>
            )}
            <div className="chat-type-in">
              <form onSubmit={(e) => onSendMessage(e)}>
                <div className="chat-type-form">
                  <input
                    type="text"
                    placeholder="Type something..."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <button type="submit">
                    <img src={Images.sent_icon} alt="" />
                  </button>
                  {isCreator() && (
                    <span className="attach-file">
                      <img
                        src={Images.attach_icon}
                        alt=""
                        onClick={attachFile}
                      />
                    </span>
                  )}
                </div>
              </form>
            </div>
          </div>
          {chooseFolder && (
            <ChooseFolder
              folderList={folder.co_folders}
              cancel={hideChooseFolder}
              save={saveMedia}
            />
          )}
        </div>
      </div>
      <Footer />
      {openRequest && (
        <div
          className="modal-wrapper"
          onClick={(e) => {
            setOpenRequest(false);
          }}
        >
          <div className="model-inner">
            <div className="model-box" onClick={handleChildClick}>
              <h3>Request Amount</h3>
              <form onSubmit={(e) => createPaymentRequest(e)}>
                <div className="form-group">
                  <input
                    value={requestPrice}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setRequestPrice(parseInt(value));
                    }}
                    type="number"
                    pattern="[0-9]*"
                    placeholder="Price"
                  />
                </div>
                <div className="modal-btn-block">
                  <div className="btn-block">
                    <input
                      type="submit"
                      className="submit"
                      value="Send Request"
                    />
                    <div className="btn-loader"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isReportUser && !isOthers && (
        <ReportUserComponent
          onSelect={setReportReason}
          onSubmit={onContinueReport}
          modalVisibility={toggleReportUser}
        />
      )}

      {isOthers && (
        <ReportMessage
          onTextChange={setReportMessage}
          onSubmit={onReportSubmit}
          modalVisibility={toggleReportUser}
        />
      )}
    </div>
  );
};

export default ChatMessage;
