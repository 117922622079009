import { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { showError } from "../utils/Common";
import "react-datepicker/dist/react-datepicker.css";

const DateFilter = ({ modalVisibility, filters, filtersSubmit }) => {
  const [startDate, setStartDate] = useState(filters?.startDate);
  const [endDate, setEndDate] = useState(filters?.endDate);

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const formatDate = (date) => {
    const d = moment(date, "yyyy-MM-DD");
    return moment(d).format("yyyy-MM-DD");
  };

  const hideModal = () => {
    if (modalVisibility) {
      modalVisibility(false);
    }
  };

  const handleChange = (date) => {
    const d = moment(date, "DD-MM-YYYY").add(1, "days");
    setStartDate(moment(d).toISOString());
  };

  const handleEndChange = (date) => {
    const d = moment(date, "DD-MM-YYYY").add(1, "days");
    setEndDate(moment(d).toISOString());
  };

  const handleSubmit = () => {
    if (!startDate) {
      showError("Please select start date");
      return;
    }

    if (!endDate) {
      showError("Please select end date");
      return;
    }

    const date1 = moment(startDate).toDate();
    const date2 = moment(endDate).toDate();

    if (date2 <= date1) {
      showError("End date should be greater than start date");
      return;
    }

    if (filtersSubmit) {
      let filter = {
        startDate,
        endDate,
      };

      filtersSubmit(filter);
    }
  };
  return (
    <div className="data-filter-wrap" onClick={hideModal}>
      <div className="data-filter-block" onClick={handleChildClick}>
        <h3>Date Filter</h3>
        <div className="data-filter-form">
          <form>
            <div className="form-group">
              <label className="datepicker" for="datepicker-3">
                <DatePicker
                  value={startDate && formatDate(startDate)}
                  placeholderText="Start Date"
                  onChange={(date) => handleChange(date)}
                />
              </label>
            </div>
            <div className="form-group">
              <label className="datepicker" for="datepicker-4">
                <DatePicker
                  value={endDate && formatDate(endDate)}
                  placeholderText="End Date"
                  onChange={(date) => handleEndChange(date)}
                />
              </label>
            </div>
            <div className="btn-block">
              <input
                className="submit"
                type="button"
                defaultValue="Apply"
                onClick={handleSubmit}
              />
              <div className="btn-loader" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
