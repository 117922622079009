import { Images } from "../../themes";
import { useDispatch, useSelector } from "react-redux";
import { AppRoute } from "../../router/routerConstants";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { useEffect, useState } from "react";
import { saveData } from "../../utils/api/apiHelper";
import { SignUp } from "../../utils/api/actionConstants";
import { toast } from "react-toastify";
import * as Validate from "../../utils/validatorXerox";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Cons_CreateFolder_Registration } from "../../utils/api/actionConstants";
import { useLocation } from 'react-router-dom';
// import { saveData } from "../../utils/api/apiHelper";
const RegistrationScreen = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
// useEffect(()=>{
//   const payload = {
//     action: Cons_CreateFolder_Registration,
//   };
//   dispatch(saveData(payload)).then((r) => {
     
//     if (r === 1) {
//       // hideModal();
//     }
//   });  

// },[])
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confEmil, setConfEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const [secondConfirm, setSecondConfirm] = useState(false);
  const [thirdConfirm, setThirdConfirm] = useState(false);

  const [selectedType, setSelectedType] = useState(1); //1 Creator , 2:Consumer

  const location = useLocation();
  const type = location.state?.type;

  useEffect(() => {
    if(type && type!='undefined'){
      setSelectedType(2);
    }
  }, []);

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onConEmailChange = (event) => {
    setConfEmail(event.target.value);
  }
  const showError = (message) => {
    toast.error(message);
    toast.clearWaitingQueue();
  };

  const handleSubmit = () => {
    let userType = "";
    if (selectedType === 2) {
      userType = "Consumer";
    } else {
      userType = "Creator";
    }
    const params = {
      name: name,
      email: email,
      password: password,
      userType: userType,
    };
    let validate = Validate.dynamicValidationNew("RegisterConsumer", params);
    if (validate !== "") {
      showError(validate);
      return;
    }
    const regex = /^[^@]+$/;
    if(!regex.test(name)){
      showError("Username should not contain the '@' character");
      return;
    }
    if (email !== confEmil) {
      showError("EMAIL do not match");
      return;
    }

    if (!confirm) {
      showError("Please select Age confirmation");
      return;
    }
    if (!secondConfirm) {
      showError("Please select local, state, and federal laws");
      return;
    }
    if (!thirdConfirm) {
      showError("Please select terms and conditions");
      return;
    }

    dispatch(
      saveData({
        action: SignUp,
        definition: "RegisterConsumer",
        data: params,
      })
    ).then((r) => {
      if (r === 1) {
        if (selectedType == "1") {
          NavigateTo(AppRoute.SETUP);
        } else {
          NavigateTo(AppRoute.CONS_Search);
        }
        // NavigateTo(AppRoute.REGIS_SUCCESS, true);
      }
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className="registration-wrap">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Registration</h1>
          </div>
          { /*<p>Discretion, Privacy, Anonymity Are Our Highest Priorities.</p> */ }
          <div className="content-account">
            <div className="my-ac-form-info reg-nt">
                <div className="form-inner-holder">
                  <p className="reg-main-txt"><b>The site will be in maintenance mode from 6:30 a.m. to 8:30 a.m. every day.</b></p>
                </div>
            </div>
            <ul>
              <li>
                <span
                  className={selectedType === 1 && "active"}
                  onClick={() => {
                    setSelectedType(1);
                  }}
                >
                  Become a Creator
                </span>

                <p>
                  <span onClick={() => NavigateTo(AppRoute.BENEFITS)}>
                    <span>
                      <img src={Images.tellMeMore} />
                    </span>
                    Tell me more
                  </span>
                </p>

              </li>
              <li>
                <span
                  className={selectedType === 2 && "active"}
                  onClick={() => {
                    setSelectedType(2);
                  }}
                >
                  Become a Member
                </span>
                <p>
                  <span onClick={() => NavigateTo(AppRoute.CBENEFITS)}>
                    <span>
                      <img src={Images.tellMeMore} />
                    </span>
                    Tell me more
                  </span>
                </p>
              </li>
            </ul>
            <div className="new-user">
              Already have an Account?{" "}
              <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login here</span>
            </div>
          </div>
        
          <div className="form-block">
            <form className="reg-horizontal-line">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Username - Not Real Name"
                  onChange={onNameChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={onEmailChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Confirm Email Address"
                  onChange={onConEmailChange}
                />
              </div>
              <div className="form-group">
                <div className="pass-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Pick a Strong Password"
                    onChange={onPasswordChange}
                  />
                  {!showPassword ? (
                    <span
                      className="hide-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <span
                      className="show-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    ></span>
                  )}
                </div>
              </div>
              <div className="agelaw">
                <span className="confirm">
                  <input
                    type="checkbox"
                    id="eighteenplus"
                    name="eighteenplus"
                    onChange={() => {
                      setConfirm(!confirm);
                    }}
                    checked={confirm}
                  />
                  <label for="eighteenplus">
                    Click to confirm you are at least 18 years of age
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="laws"
                    name="laws"
                    onChange={() => {
                      setSecondConfirm(!secondConfirm);
                    }}
                    checked={secondConfirm}
                  />
                  <label for="laws">
                    Click to confirm you are responsible for following all
                    local, state, and federal laws
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    onChange={() => {
                      setThirdConfirm(!thirdConfirm);
                    }}
                    checked={thirdConfirm}
                  />
                  <label for="terms">
                    Click to confirm that by using our site you agree to all{" "}
                    <a target="_blank" href="/terms_conditions">
                      Terms and Conditions
                    </a>
                  </label>
                </span>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleSubmit}
              />
             {/* <div className="new-user">
                Already a User?{" "}
                <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login</span>
              </div> */ }
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationScreen;
